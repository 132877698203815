jQuery(document).ready(function($){
	initcarousel();
	initmatchHeight();
	initSelecttext();
    initMobileDropdown();
});

// init carousel
function initcarousel() {
	jQuery('.carousel').slick({
		infinite: true,
		dots: true,
		arrows: true,
		speed: 500,
		fade: true,
		autoplay: true,
		autoplaySpeed: 3000,
		cssEase: 'linear',
		prevArrow: '<a href="#" class="slick-prev"></a>',
		nextArrow: '<a href="#" class="slick-next"></a>'
	});
}



// match height
function initmatchHeight() {
	jQuery('.post').matchHeight();
}

// init select text
function initSelecttext() {
	var activeText = jQuery('.nav-filter .active').text();
	var opener = jQuery('.filter-opener');
	var openerText = opener.find('span').text(activeText);

	jQuery('.filter-opener').click(function() {
		jQuery(this).parent().toggleClass('open');
		return false;
	});

	jQuery('.nav-filter li').click(function() {
		jQuery('.filter-wrap').removeClass('open');
		var text = jQuery(this).text();
		openerText.text(text);
	});

}

function initMobileDropdown() {

    jQuery('.menu-item.menu-item-type-custom.menu-item-object-custom.menu-item-has-children.dropdown').on('click', function () {

		setTimeout(function() {
			jQuery(this).toggleClass('open');
		})

	});
}